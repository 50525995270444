import React from "react";
import FAQ from "@/components/pages/faq";
import { graphql } from "gatsby";
import SEOEn from "@/components/seo/index.en";
import SEORu from "@/components/seo/index.ru";

const FaqEn = (props) => {
  const { pageContext } = props;

  return (
    <>
      {pageContext.langKey === "en" && (
        <SEOEn
          title={props.data.layoutFaq.title}
          description={props.data.layoutFaq.ogDescription?.ogDescription}
          ogImage={props.data.layoutFaq.ogImage}
        />
      )}
      {pageContext.langKey === "ru" && (
        <SEORu
          title={props.data.layoutFaq.title}
          description={props.data.layoutFaq.ogDescription?.ogDescription}
          ogImage={props.data.layoutFaq.ogImage}
        />
      )}

      <FAQ {...props} />
    </>
  );
};

export const pageQuery = graphql`
  query($locale: String!) {
    layoutFaq: contentfulLayoutFaq(node_locale: { eq: $locale }) {
      ...FAQPageFragment
    }
  }
`;

export default FaqEn;
