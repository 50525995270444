import React, { MouseEvent, useState } from "react";
import { graphql } from "gatsby";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import cn from "classnames";
import PageTitle from "@/components/kit/page-title";
import Button from "@/components/kit/button";
import * as styleConstants from "@/constants/style";
import Accordion from "./accordion";
import * as css from "./faq.modules.scss";
import { useCallback } from "react";

const FAQ = ({ data }) => {
  const { layoutFaq } = data;

  const [selectCategory, setSelectCategory] = useState(
    layoutFaq.categories[0].id
  );

  const onCategoryClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id;

    setSelectCategory(id);
  }, []);

  return (
    <div className="mb-28 desktop:pt-10 desktop1280:pt-12 desktop1440:pt-14">
      <PageTitle className={css.pageTitle}>{layoutFaq.title}</PageTitle>

      <div className={cn(css.tabs, "relative")}>
        <div
          className={cn(
            css.tabsScroll,
            styleConstants.container,
            "overflow-x-auto desktop:overflow-x-hidden"
          )}
        >
          <div
            className={
              "inline-flex pr-8 tablet:pr-10 desktop:pr-0 desktop:flex"
            }
          >
            {layoutFaq.categories.map((item, index) => (
              <Button
                variant="outlined"
                className={cn(
                  css.tab,
                  {
                    "ml-5 tablet:ml-6 desktop:ml-8 desktop1280:ml-10 desktop1440:ml-11": !!index,
                    active: selectCategory === item.id,
                  },
                  "uppercase desktop:flex-1"
                )}
                key={item.id}
                data-id={item.id}
                onClick={onCategoryClick}
              >
                {item.title}
              </Button>
            ))}
          </div>
        </div>
      </div>

      <div className={styleConstants.container}>
        <TransitionGroup
          className={
            "relative mt-4 border-t-2 border-solid tablet:mt-8 " +
            "desktop:mt-11 desktop1280:mt-12 desktop1440:mt-14"
          }
        >
          {layoutFaq.categories
            .filter((category) => selectCategory === category.id)
            .map((category) => (
              <CSSTransition
                key={category.id}
                timeout={300}
                classNames={{ ...css }}
              >
                <div>
                  {category.qaBlocks.map((block, index) => (
                    <Accordion
                      key={index}
                      title={block.question.question}
                      className={cn({ "border-solid border-t": index > 0 })}
                    >
                      <div
                        className={cn(css.plain, "py-4 desktop:pb-10")}
                        dangerouslySetInnerHTML={{
                          __html: block.answer.childMarkdownRemark.html,
                        }}
                      />
                    </Accordion>
                  ))}
                </div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default FAQ;

export const faqPageFragment = graphql`
  fragment FAQPageFragment on ContentfulLayoutFaq {
    title
    categories {
      id
      title
      qaBlocks {
        question {
          question
          id
        }
        answer {
          childMarkdownRemark {
            html
          }
          id
        }
      }
    }
    ogDescription {
      ogDescription
    }
    ogImage {
      file {
        url
        details {
          image {
            height
            width
          }
        }
      }
    }
  }
`;
