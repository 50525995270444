import React, { JSXElementConstructor, ReactElement, useState } from "react";
import cn from "classnames";
import * as css from "./accordion.modules.scss";
import { useCallback } from "react";

type AccordionProps = {
  className?: string;
  title: string;
  children: ReactElement<any | JSXElementConstructor<any>>;
};

const Accordion: React.FC<React.HTMLAttributes<any> & AccordionProps> = ({
  className = "",
  title,
  children,
}: AccordionProps) => {
  const [open, set] = useState(false);

  const toggle = useCallback(() => {
    set(!open);
  }, [open]);

  return (
    <div
      className={cn(
        css.accordion,
        className,
        { open },
        "tablet:flex relative tablet:items-start"
      )}
    >
      <div className="tablet:w-1/3 desktop:w-7/24" onClick={toggle}>
        <button
          className={cn(
            css.button,
            { active: open },
            "border border-solid flex items-center justify-center tablet:flex-shrink-0"
          )}
        >
          <div />
        </button>
      </div>

      <div
        className={"tablet:w-2/3 desktop:w-auto desktop:flex-1 desktop:min-w-0"}
      >
        <div
          className={cn(css.title, "flex flex-col justify-center")}
          onClick={toggle}
        >
          <div
            className={cn(
              { "font-semibold": open },
              "text-title-1 tablet:text-title-2 uppercase pl-18 tablet:pl-0 tablet:font-semibold"
            )}
          >
            {title}
          </div>
        </div>

        <div className={cn(css.content, "overflow-hidden")}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
